<template>
  <div class="card" v-if="isLoaded">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-2 col-md-6">
          <h3>{{ $t('kpi.digital_articles') }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 col-md-6">
          <Select
            v-model="aiAssistedFilter"
            :options="aiAssistedFilterOptions"
            id="aiAssistedFilter"
            :label="$t('article.source_type_ai_assisted')"
          />
        </div>
        <div class="col-lg-10 col-md-6">
          <app-number-of-records-selector
            :activeNumber="perPage"
            @number-of-records="setRecordsNumber"
          >
          </app-number-of-records-selector>
        </div>
      </div>
      <app-data-table
        :data="renderListOnline(onlineCurrentPage)"
        :pagination="true"
        :total-count="filteredArticles.length"
        :limit="perPage"
        :page="onlineCurrentPage"
        :config="tableConfig"
        action-id-param="documentId"
        @page-change="renderListOnline"
        :data-sorted-by="sortedBy"
        :data-sorted-order="sortedOrder"
        @data-sort-by="sortBy"
      >
      </app-data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '../../shared/DataTable'
import NumberOfRecordsSelector from './NumberOfRecordsSelector'
import { mapState } from 'vuex'
import Select from '@/components/form/select/Select'

const DEFAULT_SORT_BY = 'pageViewsCnt'
const DEFAULT_SORT_ORDER = -1
const DEFAULT_PER_PAGE_RECORDS = 5

export default {
  name: 'DigitalArticlesList',
  data () {
    return {
      onlinePageToOpen: 1,
      onlineCurrentPage: 1,
      sortedBy: DEFAULT_SORT_BY,
      sortedOrder: DEFAULT_SORT_ORDER,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      aiAssistedFilter: null,
      aiAssistedFilterOptions: [
        { title: this.$t('yes'), id: true },
        { title: this.$t('no'), id: false }
      ],
      tableConfig: {
        fields: {
          departmentIds: this.$t('kpi.department'),
          documentId: this.$t('article.title'),
          articleType: this.$t('article.article_type_title'),
          orderDate: this.$t('article.order_date'),
          productsIds: this.$t('kpi.product'),
          sharedUsers: this.$t('article.sharedUsers'),
          aiAssisted: this.$t('article.source_type_ai_assisted'),
          pageViewsNormalized: this.$t('kpi.kpi_parameters.pageViewsNormalized'),
          avgDailyUsers: this.$t('kpi.kpi_parameters.avgDailyUsers'),
          avgTimeSpentPerUserArticle: this.$t('kpi.kpi_parameters.avgTimeSpentPerUserArticle'),
          avgTimeSpentPerUserGallery: this.$t('kpi.kpi_parameters.avgTimeSpentPerUserGallery'),
          imagesCnt: this.$t('kpi.performanceItems.imagesCnt'),
          videosCnt: this.$t('kpi.performanceItems.videosCnt'),
          fbSharesCnt: this.$t('kpi.performanceItems.fbShares'),
          disqusCnt: this.$t('kpi.performanceItems.disqusComments')
        },
        toolTip: {
          pageViewsCnt: this.$t('kpi.toolTip.pageViewsCnt'),
          videosCnt: this.$t('kpi.toolTip.videosCnt'),
          actions: this.$t('kpi.toolTip.actionArticleDetail')
        },
        render: {
          documentId: (documentId) => {
            return this.getArticleDetailsById(documentId)
          },
          articleType: (articleType) => {
            return this.$t('article.article_type.' + articleType)
          },
          departmentIds: (departments) => {
            return departments.map(departmentId => {
              const department = this.$store.getters['department/getById'](departmentId)
              if (department) {
                return department.name
              }
              return departmentId
            }).join(', ')
          },
          productsIds: (products) => {
            const mainProducts = []
            products.map(productId => {
              const product = this.$store.getters['edonProduct/getById'](productId)
              if (product && product.title) {
                mainProducts.push(product.title)
              }
            })
            return mainProducts.join(', ')
          },
          sharedUsers: (sharedUsers) => {
            return sharedUsers.map(sharedUser => {
              return sharedUser.Name
            }).join(', ')
          },
          aiAssisted: (aiAssisted) => {
            if (typeof aiAssisted !== 'boolean') {
              return ''
            }
            return aiAssisted ? this.$t('yes') : this.$t('no')
          },
          pageViewsCnt: (pageViewsCnt) => {
            return this.$options.filters.formatNumber(pageViewsCnt)
          }
        },
        actions: {
          detail: 'articleByDocumentId'
        }
      }
    }
  },
  methods: {
    getArticleDetailsById (documentId) {
      const article = this.onlineArticles.filter(article => {
        return article.documentId === documentId
      })
      if (article[0]) {
        let details = article[0].title + '<br><span class="code">' + documentId + '</span>'
        if (article[0].fromPrint) {
          details += ' <i class="far fa-newspaper" title="' + this.$t('article.copied_from_print') + '"></i>'
        }
        return details
      }
      return ''
    },
    setRecordsNumber (number) {
      this.onlineCurrentPage = 1
      this.perPage = number
    },
    mergeUniqueEntities (array1 = [], array2 = []) {
      const combinedArray = [...array1, ...array2]
      const allObjectsWithEntityId = combinedArray.every(item => item && typeof item === 'object' && 'EntityId' in item)

      if (allObjectsWithEntityId) {
        return combinedArray.reduce((acc, current) => {
          const x = acc.find(item => item.EntityId === current.EntityId)
          if (!x) {
            return acc.concat([current])
          } else {
            return acc
          }
        }, [])
      } else {
        return [...new Set(combinedArray)]
      }
    },
    renderListOnline (pageNumber = 1) {
      const recordsToDisplay = []
      if (this.filteredArticles && this.filteredArticles.length) {
        this.onlinePageToOpen = pageNumber
        for (let i = this.startOnline; i <= this.stopOnline; i++) {
          recordsToDisplay.push(this.filteredArticles[i])
        }
        this.onlineCurrentPage = this.onlinePageToOpen
      }
      return recordsToDisplay
    },
    sortBy (field) {
      if (field === this.sortedBy) {
        this.sortedOrder = -1 * this.sortedOrder
      }
      this.sortedBy = field
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    filteredArticles () {
      return this.onlineArticles.filter(record =>
        (!this.filter.user ||
          record.sharedUsers.some(user => parseInt(user.EntityId) === parseInt(this.filter.user))) &&
        (typeof this.aiAssistedFilter !== 'boolean' ||
          record.aiAssisted === this.aiAssistedFilter)
      )
    },
    /* eslint-disable */
    onlineArticles () {
      const onlineArticles = this.$store.getters['editorialPerformance/onlineArticles']

      onlineArticles.forEach(onlineArticle => {
        onlineArticle.sharedUsers = this.mergeUniqueEntities(onlineArticle.sharedUsers || [], onlineArticle.originalSharedUsers || [])
        onlineArticle.departmentIds = this.mergeUniqueEntities(onlineArticle.departmentIds || [], onlineArticle.originalDepartmentIds || [])
      })

      return onlineArticles.sort((a, b) => {
        if (a[this.sortedBy] < b[this.sortedBy]) {
          return -1 * this.sortedOrder
        }
        if (a[this.sortedBy] > b[this.sortedBy]) {
          return this.sortedOrder
        }
        return 0
      })
    },
    startOnline () {
      return (this.onlinePageToOpen - 1) * this.perPage
    },
    stopOnline () {
      if ((this.filteredArticles.length - this.startOnline) >= this.perPage) {
        return (this.onlinePageToOpen * this.perPage) - 1
      } else {
        return this.filteredArticles.length - 1
      }
    },
    filter () {
      return this.$store.getters['editorialPerformance/filter']
    }
  },
  props: ['isLoaded'],
  components: {
    appDataTable: DataTable,
    appNumberOfRecordsSelector: NumberOfRecordsSelector,
    Select
  }
}
</script>
